<template>
<div>
    

    <!-- banner start -->
    <section
      class="banner_sec webinar"
      
    >
      <div class="banner_hed">
        <h1 class="heading_1">Ergo Anytime. Anywhere. Any Device.</h1>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box webinars_heading_banner">
              <h3 class="heading_3">Are your employees working from home?</h3>
              <h3 class="heading_3">At the office? A bit of both?</h3>
              <h3 class="heading_3">We’ve got you covered.</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- right left text start -->
    <section class="right_left_text_sec padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xl-5">
            <div class="right_text_virtual">
              <h5 class="heading_5">Virtual Evaluations</h5>
              <p>
                No matter where your employees are working these days, making sure that they  working safely, productively and setup ergonomically, is essential.  We can help your employees anywhere in the world through virtual evaluations. 
              </p>
            </div>
          </div>
          <div class="col-md-12 col-xl-1">
          <div class="web_arrow">
            <img src="../../assets/home/images/webinars/arrow.svg" alt="img" />
          </div>
          </div>
          <div class="col-md-12 col-xl-6">
            <div class="right_text_virtual virtual_right_box">
              <h5 class="heading_5">Evaluations Include</h5>
              <ul class="m-0 pl_22">
                <li><p>A full analysis of work setup</p></li>
                <li>
                  <p>A personalized action plan</p>
                </li>
                <li>
                  <p>Behavioral & equipment recommendations</p>
                </li>
                <li>
                  <p>Automated ergo reports & follow-up surveys</p>
                </li>
                <li>
                  <p>Backend data base and metrics</p>
                </li>
                <li>
                  <p>A dedicated client manager</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-12">
            <div class="webinars_btn text-center mt_60">
              <router-link :to="{ name : 'learn_more' }" class="btn btn_primary px_82">Schedule Today!</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- right left text end -->

    <!-- WFH start -->
    <section class="wfh_sec bg_grey padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="wfh_heading text-center">
              <h3 class="heading_3 text_primary font_bold">
                WFH and Hybrid Webinars
              </h3>
            </div>
          </div>
        </div>
        
          <div class="row">
            <div id="accordionExample" class="accordion d-flex">
              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                  <img src="../../assets/home/images/webinars/w1.png" alt="img" />
                  
                    <div class="accordion-item">
                      <h2 id="headingOne" class="accordion-header">
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          class="accordion-button collapsed"
                        >
                          Ergo & Productivity
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                        class="accordion-collapse collapse"
                      >
                        <div class="accordion-body">
                          <p>
                            With the dramatic change in our workplaces and work
                            styles, being on top of our ergonomics, healthy work
                            practices and staying focused and productive is more
                            important than ever.
                          </p>
                          <h6>Webinar Topics Include:</h6>
                          <ul class="m-0 pl_20">
                            <li>Optimizing Your Schedule</li>
                            <li>Ergo Anywhere Setup Guidelines.</li>
                            <li>DIY Ergo</li>
                            <li>Productivity Strategies</li>
                            <li>Focus Exercises</li>
                            <li>Dynamic Stretches</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>


              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                  <img src="../../assets/home/images/webinars/w2.png" alt="img" />
                    <div class="accordion-item">
                      <h2 id="headingTwo" class="accordion-header">
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          class="accordion-button collapsed"
                        >
                        Ask The 
                        Ergonomist
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                        class="accordion-collapse collapse"
                      >
                        <div class="accordion-body">
                          <p>
                            “Ask the Ergonomist” is an opportunity to both learn from and ask questions of a professional ergonomist. 
    
                           
                           
                           
                          </p>
                           
                            <h6>
                              Webinar Topics Include:
                            </h6>
                          <ul class="m-0 pl_20">
                           <li>
                           Set Yourself Up for Success
                           </li>
                            <li>Troubleshooting Strategies</li>
                            <li>Ask The Ergonomist Session</li>
                            <li>Learn From Each Other</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                  <img src="../../assets/home/images/webinars/w3.png" alt="img" />
                    <div class="accordion-item">
                      <h2 id="headingThree" class="accordion-header">
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          class="accordion-button collapsed"
                        >
                        Stress Less Thrive More
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                        class="accordion-collapse collapse"
                      >
                        <div class="accordion-body">
                          <p>
                            Between WFH, Hybrid Working or Returning to the Office, back-to-back video meetings and many unknowns, it’s fair to say that stress levels are on the rise.
                          </p>
                          <h6>Webinar Topics Include:</h6>
                          <ul class="m-0 pl_20">
                            <li>Spotting Stress Overload</li>
                            <li>Stress Management</li>
                            <li>Energy & Time Management</li>
                            <li>The Art of Self-Care</li>
                            <li>Q & A</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-3">
                <div class="wfh_box">
                  <img src="../../assets/home/images/webinars/w4.png" alt="img" />
                    <div class="accordion-item">
                      <h2 id="headingFour" class="accordion-header">
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                          class="accordion-button collapsed"
                        >
                        Ergo Tips for Parents & Kids
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                        class="accordion-collapse collapse"
                      >
                        <div class="accordion-body">
                          <p>
                            If you are juggling both working and parenting from home, we know your struggles are real!  This fun and informative webinar will arm you with strategies to structure the day with your children in a way that keeps everyone productive, organized and calm (or shall we say, calmer!) 
                          </p>
                          <h6>Webinar Topics Include:</h6>
                          <ul class="m-0 pl_20">
                            <li>Organizing Your Day</li>
                            <li>Productivity Strategies</li>
                            <li> Setting Everyone Up For 
                              Success</li>
                            <li>Kid-Friendly Ergo </li>
                            <li> Q & A</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <!-- last div -->
            </div>
          </div>
        
      </div>
    </section>
    <!-- WFH end -->

    <!-- return back to office start -->
    <section class="bact_to_office padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="back_heading text-center">
              <h3 class="heading_3 mb_80 font_bold text_black">Returning Back To the Office?  
                Find out how we can help.</h3>
                <router-link :to="{ name : 'learn_more' }" class="btn btn_primary">Let’s Talk</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- return back to office end -->

</div>

</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "virtual_service",
  mounted() {
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>